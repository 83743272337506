import React from "react"
import { useTranslation } from "react-i18next"

import ArrowRight from "images/arrow-right.inline.svg"

const Research = () => {
  const { t } = useTranslation()

  return (
    <section
      className="container pb-100 anchor-top-spacing content-padding-x"
      id="research"
    >
      <h2 className="bold-40 text-center mb-4">{t("Research and Academy")}</h2>
      <div className="lh-2 mb-4">
        <p className="light-24 mb-2">
          {t(
            "Our company provides expertise for your research and teaching projects or will participate in the clinical research of your medical devices in the vascular field to ensure the smooth running of your CE marking procedures"
          )}
          .
        </p>
        <p className="semi-bold-24 text-purple-dark text-center">
          {t("Do you have specific needs in medical research?")}
          <br />
          <span className="light-24 text-italic">
            {t("Do not hesitate to contact us")}
          </span>
        </p>
      </div>
      <div className="text-center">
        <button
          className="btn-primary regular-20"
          onClick={() => (window.location = "mailto:contact@uvasc.io")}
        >
          {t("Contact")}
          <ArrowRight height="15" className="ms-1" />
        </button>
      </div>
    </section>
  )
}

export default Research
