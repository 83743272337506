import React from "react"
import { useTranslation } from "react-i18next"

import Layout from "components/Layout"
import Seo from "components/Seo"
import OurTeam from "components/Company/OurTeam"
import Recruitment from "components/Company/Recruitment"
import Research from "components/Company/Research"
import WhoAreWe from "components/Company/WhoAreWe"

const CompanyPage = () => {
  const { t } = useTranslation()
  const title = t("Care with code by coding with care")
  const seoTitle = t("UVASCLAB, our team")
  const seoDescription = t("Care with code by coding with care")

  return (
    <Layout hasHeader={true} hasPreFooter={false} title={title}>
      <Seo title={seoTitle} description={seoDescription} />
      <WhoAreWe />
      <OurTeam />
      <Research />
      <Recruitment />
    </Layout>
  )
}

export default CompanyPage
