import React from "react"
import { useTranslation } from "react-i18next"

import ArrowRight from "images/arrow-right.inline.svg"
import "assets/sass/call_to_action_2.scss"

const Recruitment = () => {
  const { t } = useTranslation()

  return (
    <section className="purple-section pb-100 py-100" id="recruitment">
      <div className="container content-padding-x">
        <h2 className="bold-40 text-center mb-4">{t("Recruitment")}</h2>
        <div className="lh-2 mb-4">
          <p className="light-24 mb-2">
            {t(
              "UVASC Lab is hosted at the hospital in Pont l'Abbé and at the LAB-STICC of ENSTA Bretagne in Brest"
            )}
            .
          </p>
          <p className="light-24 mb-2">
            {t(
              "We are made up of a passionate, dynamic, multilingual and experienced team"
            )}{" "}
            {t(
              "with currently a managing director, a vacsular doctor and two senior engineers with a science thesis in biomedical signal processing"
            )}
            .
          </p>
          <p className="light-24 mb-2">
            {t(
              "We can regularly welcome interns in engineering or development"
            )}
            .
          </p>
          <p className="light-24 mb-2">
            {t(
              "If you are interested, do not hesitate to send us your application"
            )}
            .
          </p>
        </div>
        <div className="text-center">
          <button
            className="btn-primary regular-20"
            onClick={() => (window.location = "mailto:contact@uvasc.io")}
          >
            {t("Apply")}
            <ArrowRight height="15" className="ms-1" />
          </button>
        </div>
      </div>
    </section>
  )
}

export default Recruitment
