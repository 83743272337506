import React from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"

const WhoAreWe = () => {
  const { t } = useTranslation()

  return (
    <section className="container page-content-top">
      <h2 className="bold-40 text-center mb-4">{t("Who are we?")}</h2>
      <div className="lh-2">
        <p className="light-24 mb-2">
          {t(
            "UVASC LAB is an innovative digital company in the vascular field; specialized in biomedical multimodal signal processing"
          )}
          .
        </p>
        <p className="light-24 mb-2">
          {t(
            "UVASC LAB was founded in August 2020 by Wit HAERTLÉ to valorise a end-of-study work in vascular medicine on 3D limb volumetry in lymphoedema monitoring"
          )}
          .
        </p>
        <p className="light-24 mb-2">
          {t(
            "The mission of our research and development company is to create or improve innovative, practical and accessible digital care tools for all caregivers and patients"
          )}
          .
        </p>
        <blockquote className="blockquote bold-30 text-center mb-2">
          {t("We want to create links through empathy and transparencye")}.{" "}
          {t("We believe in liberating knowledge and passing it on")}.{" "}
          {t("Our motto is to heal with code, by coding with care")}.
        </blockquote>
        <p className="light-24 mb-2">
          {t(
            "We propose as a first development a professional tool intended to save precious time in the daily practice of physiotherapists, doctors, nurses and pharmacists"
          )}
          .
        </p>
        <p className="light-24 mb-2">
          {t(
            'This tool consists of an iOS mobile application "UVASC" using augmented reality and an integrated infrared sensor to perform a simple digital twin and limb volume calculation for lymphoedema monitoring'
          )}
          .
        </p>
        <p className="light-24 mb-2">
          {t(
            'This mobile application is a companion to a web-based library application "UVASC Cloud" where data is sent for monitoring'
          )}
          .{" "}
          {t(
            "This tool can be used for research to meet the needs and expectations of industry"
          )}
          .
        </p>
        <p className="light-24 mb-2">
          {t(
            "UVASC LAB will improve this tool to help practitioners in their diagnoses and prescriptions"
          )}
          .{" "}
          {t(
            "Procedures are underway to obtain the CE marking for Medical Software (SAMD - Software As Medical Device)"
          )}
          .
        </p>
        <p className="semi-bold-24 mb-2">
          {t("We are a partner of the")}{" "}
          <a
            href="https://www.ensta-bretagne.fr/fr/recherche-en-technologies-de-linformation-et-de-la-communication-lab-sticc"
            target="_blank"
            rel="noreferrer noopener"
            className="link-arrow"
          >
            LABSTICC de l’ENSTA Bretagne
          </a>{" "}
          {t("in Brest and the")}{" "}
          <a
            href="https://www.univ-brest.fr/getbo/fr"
            target="_blank"
            rel="noreferrer noopener"
            className="link-arrow"
          >
            GETBO
          </a>{" "}
          {t("at the")}{" "}
          <a
            href="https://www.chu-brest.fr/fr"
            target="_blank"
            rel="noreferrer noopener"
            className="link-arrow"
          >
            CHU de Brest
          </a>
          .{" "}
          {t(
            "We are housed in the Hôtel-Dieu de Pont l'Abbé hospital of the HSTV group"
          )}
          .
        </p>
        <p className="light-24 mb-2">
          {t(
            "We participate in the training of engineering students and medical interns by offering them « proof of concept » internship subjects or pilot studies as part of their thesis with a weekly follow-up with our expertise"
          )}
          .
        </p>
        <p className="light-24 mb-2">
          {t(
            "These missions are carried out while respecting the confidentiality of the projects"
          )}
          .
        </p>
      </div>
      <StaticImage
        alt={t("Tablet with 3D sensor")}
        layout="constrained"
        placeholder="blurred"
        src="../../images/entreprise-photo-tablette-mains-arriere.webp"
        width={936}
      />
    </section>
  )
}

export default WhoAreWe
