import React from "react"

import "assets/sass/team_member.scss"

const TeamMember = ({ children, name, description }) => {
  return (
    <div className="team-member-container text-center lh-1-125 mb-3">
      {children}
      <p className="semi-bold-18">{name}</p>
      <p className="regular-14">{description}</p>
    </div>
  )
}

export default TeamMember
